<template>
  <div id="container">
    <div class="about main">
      <div class="section">
        <div v-if="question.type==2">
          <div class="qs-title">{{activity.title}}</div>
          <div class="about-title">
            <div>多选题（最多选三项） </div>
            <div>Multiple choice questions (choose up to 3 options)</div>
          </div>
        </div>
        <div v-if="question.type==1">

          <div class="qs-title">{{activity.title}}</div>
          <div class="about-title"> 第{{ questionIndex+1 }}题 / 共{{ questions.length }}题  Question {{ questionIndex+1 }}/{{ questions.length }}</div>
        </div>

        <div v-if="qtoday">
          <div class="qs-title"></div>
          <div class="mvbutton" @click="goMain" style="padding: 0 1rem">该问题已答过，返回主页</div>
        </div>

        <div class="about-list"  v-if="this.questions.length > 0">

          <div class="list-title">{{question.title}}</div>
          <div class="list-options">
            <div class="option"
              v-for="(item, index) in question.content"
              @click="pick(index)"
              :class="{ selected: -1 !== (question.picked || []).indexOf(index) ,
                        right: answered === 1 && (index+1) === 1*question.answer, 
                        wrong: answered === 1 && (index+1) !== 1*question.answer
                      }"
              :key=index>
              {{item}}
            </div>
          </div>
          <div id="explain" class="answer-explain" v-if="answered === 1 && question.explain">
            <div class="answer-explain-title">答案解析</div>
            <div class="answer-explain-content">{{question.explain || '无'}}</div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="mvbutton" @click="next" v-if="picked">下一题<br />NEXT</div>
      <div class="mvbutton disabled" v-else>下一题<br />NEXT</div>
    </footer>
  </div>
</template>

<script>
import api from '@/api/api'
import request from '@/api/request'
import router from "@/router"
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  data() {
    return {
      questionIndex: 0,
      questions: [],
      activity:[],
      answered: 0,
      submitting: 0,
      qtoday: 0,
    }
  },
  computed: {
    question() {
      return this.questions[this.questionIndex] || {}
    },
    answer() {
      let data = this.questions.map(item => {
        return {
          qid: item.qid,
          answer: item.picked ? (item.type === "1" ? item.picked[0] + 1 : item.picked.map(m => m+1).join(',')) : -1
        }
      })
      return JSON.stringify(data)
    },
    picked() {
      let q = this.questions[this.questionIndex] || {}
      return q.picked && q.picked.length
    }
  },
  methods: {
    load() {
      this.questionIndex = 0
      this.questions = []
      api.question(this.$route.query.aid).then(r => {
        this.questionIndex = 0
        this.questions = r.data.data.questions || []
        this.activity = r.data.data.activity || {}
        this.qtoday = r.data.data.qtoday || 0
        this.answered = 0
        console.log(r.data.data.questions.map(item => item.answer))
        if (this.$route.query.aid == 941 && this.qtoday) {
          this.$router.push({
            name: 'Goodslist',
            query: {
            }
          })
        }
      })
      this.submitting = 0
    },
    goMain() {
      this.$router.push(({
        path: '/main'
      }))
    },
    next() {
      if (this.questions.length === 0) {
        return 
      }
      if (this.question.type*1 === 1) {
        if (this.question.picked[0]+1 === 1*this.question.answer ) {
          this.answered = 2
        } else {
          this.answered ++
          if (!this.question.explain) {
            // this.answered ++
          }
        }
        if (this.answered > 1) {
          // real next
        } else {
          // hold once and display tips
          // setTimeout(() => {
          //   document.getElementById("explain").scrollIntoView({behavior: 'smooth'})
          // }, 500)
          return
        }
      }
      if (this.questionIndex >= this.questions.length -1) {
        if (!this.submitting) {
          this.submitting = 1
          request.post('customize/sports-museum/submitquests', {
            aid: this.$route.query.aid,
            answer: this.answer
          }).then(r => {
            if (this.$route.query.aid == 942) {
              if (this.$route.query.goods) {
                this.$router.push({
                  name: 'Goodslist',
                  query: {
                    jid: this.$route.query.jid || 0,
                    acid: this.$route.query.acid || 0
                  }
                })
                return
              }
              if (r.data.data.score >= 50) {
                // if (this.$route.query.skip) {
                //   this.$router.push({
                //     name: 'Goodslist'
                //   })
                //   return
                // }
                this.$router.push({
                  name: 'Experiencelist',
                  query: {
                    jid: this.$route.query.jid || 0,
                    acid: this.$route.query.acid || 0
                  }
                })
              } else {
                this.$router.push({
                  name: 'Goodslist',
                  query: {
                    jid: this.$route.query.jid || 0,
                    acid: this.$route.query.acid || 0
                  }
                })
              }
              return ;
            } else if (this.$route.query.aid == 941) {
              // this.$router.push({
              //   name: 'question',
              //   query: {
              //     aid: 942
              //   }
              // })
              this.$router.push({
                name: 'Goodslist',
                query: {
                }
              })
              return ;
            } else if (this.$route.query.aid == 938) {
              this.$router.push({
                name: 'Experiencelist',
                query: {
                }
              })
              return;
            }

            if (r.data.data.cats && r.data.data.cats.length) {
              router.push({
                name: 'question',
                query: {
                  aid: r.data.data.cats[0]
                }
              })
            }
            setTimeout(() => {
              this.submitting = 0
            }, 1000)
          }).catch(() => {
            this.submitting = 0
          })
        }
      } else {
        this.answered = 0 // reset answer state
        this.questionIndex++
      }
    },
    pick(index) {
      if (this.answered > 0) {
        return
      }
      var idx
      let q = this.questions[this.questionIndex]
      if (q.type === "1") {
        q.picked = [index]
      } else {
        q.picked = q.picked || []
        idx = q.picked.indexOf(index)
        if (idx !== -1) {
          q.picked.splice(idx, 1)
        } else {
          if (q.picked.length >= 3) {
            this.$message({
              message: "最多可选 3 项",
              type: 'warning'
            })
          } else {
            q.picked.push(index)
          }
        }
      }
      this.$set(this.questions, this.questionIndex, q)
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    $route(to, from) {
      this.load()
    }
  }
}
</script>

<style scoped>
#container{
  display: flex; /* displays flex-items (children) inline */
  flex-direction: column; /* stacks them vertically */
  height: 100%; /* needs to take the parents height, alternative: body {display: flex} */
}
.main {
  flex: 1; /* takes the remaining height of the "container" div */
  overflow: auto; /* to scroll just the "main" div */
  height: 100%; /* takes the visible area of the "main" div */
  overflow: auto; /* recommended */
}

.qs-title
{
  font-size: 34px;
  color: #fff;
  margin-bottom: 15px;
}
.about{
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  margin-top: 30px;
}
.about .about-title{
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 20px;
}
.about .about-list .list-title{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 35px;
  background: inherit;
  background-color: rgba(187, 138, 76, 1);
  border: none;
  border-radius:0 4px 0 4px;
  box-shadow: 5px 5px 5px rgb(149 104 47);
  margin-bottom: 20px;
  text-align: left;
}
.about .about-list .list-options{
  box-sizing: border-box;
}
.about .about-list .list-options .option{
  width: 100%;
  height: 55px;
  margin-bottom: 20px;
  color: #433C30;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(209, 187, 144, 0.933333333333333);
  font-size: 13px;
}
.about .about-list .list-options .selected{
  background-color: rgba(187, 138, 76, 1);
  color: #FFFFFF;
}

.disabled {
  background-color: rgb(150, 150, 150);
  box-shadow: 5px 5px 5px rgb(150, 150, 150);
}

.about .about-list .list-options .selected.wrong {
  background-color: #A21717;
}
.about .about-list .list-options .right {
  background-color: #1E8C41;
}

.answer-explain {
  padding: 8px 10px;
  background: rgba(255, 235, 205, 0.7);
  line-height: 1.8;
}

.answer-explain-title {
  font-size: 14px;
}

.answer-explain-content {
  text-align: left;
  font-size: 12px;
}

</style>
